<template>
  <div>
    <location-list />
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import LocationList from './LocationList.vue'

export default {
  components: {
    LocationList,
  },

  setup() {
    onMounted(() => {
      if (window.location.href.indexOf('/locations/list-add') > -1) {
        setTimeout(() => {
          document.getElementById('add-location-btn').click()
        }, 1000)
      }
    })

    return {

    }
  },
}
</script>
